.messenger {
    display: grid;
    width: 100%;
    height: 90vh;
    background: #eeeef1;
  
    grid-template-columns: 350px auto;
    grid-template-rows: 60px auto 60px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
  }
  
  .container {
    padding: 10px;
  }
  
  .scrollable {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  .sidebar {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
  }
  
  .content {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
  }
  
  .footer {
    grid-column-start: 2;
    background: white;
  }


  .toolbar {
    align-items: center;
    text-align: center;
    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;
  
    position: sticky;
    top: 0px;
  }
  
  @supports (backdrop-filter: blur(20px)) {
    .toolbar {
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(20px);
    }
  }
  
  .toolbar-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .message-list-container {
    padding: 10px;
    padding-bottom: 50px;
  }

  ._3Sxu7 > AgoraUIKit{
    position: absolute !important;
    right: 2% !important;
    top: 2% !important;
    width: 12% !important;
  }

  
.dropzone {
  text-align: center;
  padding: 50px;
  border-radius: 10px;
  /* border: 3px dashed #eeeeee; */
  background-color: #d8defd;
  color: #111a79;
  margin: 20px;
}


  /* .left-items, .right-items {
    flex: 1;
    padding: 10px;
    display: flex;
  }
  
  .right-items {
    flex-direction: row-reverse;
  }
  
  .left-items .toolbar-button {
    margin-right: 20px;
  }
  
  .right-items .toolbar-button {
    margin-left: 20px;
  }
  
  .left-items .toolbar-button:last-child,
  .right-items .toolbar-button:last-child {
    margin: 0;
  } */